import React, { useState, useEffect } from "react";
import Loading from './loading';

function AboutUsSection () {
    return (
        <div id="about-us-section" className="pt-20 pb-12 bg-zinc-200 relative">
            <div className="z-30 gap-1 columns-1 relative container mx-auto px-4">
                <div className="section-head block items-center justify-center mb-12 text-center">
                    <h2 className="title">Why Customer Love To</h2>
                    <h2 className="title"><span className="text-primary">Choose Us</span></h2>
                    <p>There are many variations of passages of Lorem Ipsum typesetting industry has been the industry's standard dummy text ever since the been when an unknown printer.</p>
                </div>
            </div>
        </div>
    );

}

export default AboutUsSection;