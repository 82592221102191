import React, { useState, useEffect } from "react";
import { Route } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Header from './components/ui/header';
import Carousel from './components/ui/carousel';
import AboutUsSection from './components/ui/aboutus-section';
import Footer from './components/ui/footer';
import Loading from './components/ui/loading';

import HomePage from './pages/Home';
import ContactPage from './pages/Contact';
import './assets/css/main.css';
import './assets/fonts/poppins/Poppins-Regular.ttf';

function App() {

  const [loading, setLoading] = useState(true);
  
  document.body.classList.add('font-kutko-regular','overflow-hidden');
  document.addEventListener('readystatechange', function() {
    if (document.readyState === 'complete') {
      setLoading(false);
    }
  });
  if(!loading){
    document.body.classList.remove('overflow-hidden');
  }

  return (
      <>
        {loading ? (
            <Loading />
        ) : (
            <div>
            <Header />
            <Carousel />
            <AboutUsSection />
            <Footer />
            </div>
        )}
    </>
  );
}

export default App;
