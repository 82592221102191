// loadin.js
import React from 'react';
import logo from "../../assets/images/logo/kutko-logo-stari-dark-small.png";
import "../../assets/css/loader.css";

const Loading = () => (
    <div className="overflow-hidden flex justify-center items-center h-screen">
        <div className="spinner">
            <img src={logo} className="loading-logo" alt="logo" />
            <span>Loading...</span>
        </div>
    </div>
);

export default Loading;